<script>
import L from "leaflet";
import {
  LMap,
  //LPolyline,
  LMarker,
  LIcon,
  LTooltip,
  LTileLayer,
  LLayerGroup,
  //LControlLayers,
  LControl,
} from "vue2-leaflet";
//import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
/**
 * Map component
 */

export default {
  props: ["nord", "sud", "tech", "loader"],
  mounted() {
    
  },
  components: {
    LMap,
    LMarker,
    LIcon,
    LTooltip,
    LTileLayer,
    LLayerGroup,
    //LControlLayers,
    LControl,
    //"l-marker-cluster": Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      filterType: 1,
      noFilter: null,
      serialFilter: null,
      filterStatus: false,
      mapZoom: 6,
      mapCenter: [27.105314, -7.684968],

      centersPositionsType1: [],
      centersPositionsType2: [],

      icon: L.icon({
        iconUrl: require("@/assets/images/iconDevice.svg"),
        iconSize: [41, 41],
        iconAnchor: [16, 37],
      }),

      clusterOptions: {
        showCoverageOnHover: false,
      },

      imageDevice: require("@/assets/images/iconDevice.svg"),
      imageDeviceOrange: require("@/assets/images/iconDevice-Orange.svg"),
      imageDeviceGreen: require("@/assets/images/iconDevice-Green.svg"),
      imageDeviceGray: require("@/assets/images/iconDevice-Gray.svg"),
      imageDeviceGreenDark: require("@/assets/images/iconDevice-GreenDark.svg"),

      status1: true,
      status2: true,
      status3: true,

      /* imageInsuline: require("@/assets/images/Insuline.svg"),
      imageSerum: require("@/assets/images/Serum.svg"),
      imagePNI: require("@/assets/images/PNI.svg"),
      imageCovid: require("@/assets/images/VaccinCovid19.svg"),

      imageInsulinegray: require("@/assets/images/Insulinegray.svg"),
      imageSerumgray: require("@/assets/images/Serumgray.svg"),
      imagePNIgray: require("@/assets/images/PNIgray.svg"),
      imageCovidgray: require("@/assets/images/VaccinCovid19gray.svg"), */


      imageClear: require("@/assets/images/clearmap.svg"),
      imageReset: require("@/assets/images/resetzoom.svg"),
      zoomIn: require("@/assets/images/zoomin.svg"),
      zoomOut: require("@/assets/images/zoomout.svg"),

      //url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
      url: "http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}",
      //url: "http://maps.google.com/maps?file=api&amp;v=2&amp;hl=en&amp;sensor=true",
      staticAnchor: [16, 10],
      iconAnchor: [16, 37],
      iconSize: [22, 22],
      tooltipAnchor: [0, -30],

      loaderDevice: false,
    };
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  methods: {
    setZoomIn() {
      this.mapZoom = this.mapZoom + 1;
    },
    setZoomOut() {
      this.mapZoom = this.mapZoom - 1;
    },
    setResetZoom() {
      let center = [27.105314, -7.684968];
      const mapComponent = this.$refs.map;
      let start = center;
      let end = center;
      mapComponent.fitBounds([start, end], { maxZoom: 6 });
    },
    updateZoom(event) {
      this.mapZoom = event;
    },
    setClearMap() {
      this.clearDevices();
      this.provincesPositions = this.provinces;
      this.setResetZoom();
    },
    changeStatus1() {
      this.status1 == true ? this.status1 = false : this.status1 = true
    },
    changeStatus2() {
      this.status2 == true ? this.status2 = false : this.status2 = true
    },
    changeStatus3() {
      this.status3 == true ? this.status3 = false : this.status3 = true
    },

    
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  watch: {
    /* centers(newVal){
    newVal.map((obj) => {
      switch (obj.type) {
        case 1:
          this.centersPositionsType1.push(obj);
          break;
        case 2:
          this.centersPositionsType2.push(obj);
          return;
      }
    });
  }, */
  },
  
  
  beforeDestroy() {
    //this.$refs.map.removeLayer(this.$refs.markerCluster)
  },
};
</script>

<template>
  <div>
    <div
      v-if="loader == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>

    <div style="height: 100%">

      <l-map
        style="height: 120vh"
        ref="map"
        :zoom="mapZoom"
        v-on:update:zoom="updateZoom($event)"
        :min-zoom="5"
        :options="{ zoomControl: false }"
        :center="mapCenter"
      >
        <l-tile-layer :url="url" :subdomains="['mt0', 'mt1', 'mt2', 'mt3']" />
          <l-layer-group :visible="status1" layerType="overlay" name="nord">
          <l-marker
            :zIndexOffset="100"
            v-for="marker in nord"
            :key="marker.id"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageDeviceGreenDark" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <div>
                <span style="font-weight: 550"
                  >{{ $t("tooltips.name.text") }} :
                </span>
                {{ marker.name }}<br />
                <span v-if="marker.type" style="font-weight: 550"
                  >Type :
                </span>
                {{ marker.type ? marker.type : null }}<br v-if="marker.type" />
                <span v-if="marker.address" style="font-weight: 550"
                  >{{ $t("tooltips.address.text") }} :
                </span>
                {{ marker.address ? marker.address : null }}
              </div>
            </l-tooltip>
          </l-marker>
          </l-layer-group>
          <l-layer-group :visible="status2" layerType="overlay" name="sud">
          <l-marker
            :zIndexOffset="100"
            v-for="marker in sud"
            :key="marker.id"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageDeviceGreen" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <div>
                <span style="font-weight: 550"
                  >{{ $t("tooltips.name.text") }} :
                </span>
                {{ marker.name }}<br />
                <span v-if="marker.type" style="font-weight: 550"
                  >Type :
                </span>
                {{ marker.type ? marker.type : null }}<br v-if="marker.type" />
                <span v-if="marker.address" style="font-weight: 550"
                  >{{ $t("tooltips.address.text") }} :
                </span>
                {{ marker.address ? marker.address : null }}
              </div>
            </l-tooltip>
          </l-marker>
          </l-layer-group>
          <l-layer-group :visible="status3" layerType="overlay" name="tech">
          <l-marker
            :zIndexOffset="100"
            v-for="marker in tech"
            :key="marker.id"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageDevice" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <div>
                <span style="font-weight: 550"
                  >{{ $t("tooltips.name.text") }} :
                </span>
                {{ marker.name }}<br />
                <span v-if="marker.region" style="font-weight: 550"
                  >Région :
                </span>
                {{ marker.region ? marker.region : null }}<br v-if="marker.region" />
                <span v-if="marker.equipe" style="font-weight: 550"
                  >Equipe :
                </span>
                {{ marker.equipe ? marker.equipe : null }}
              </div>
            </l-tooltip>
          </l-marker>
          </l-layer-group>
          <l-control>
          <div style="background: rgba(255, 255, 255, 0.4)">
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatus1"
              ><img v-if="status1" :src="imageDeviceGreenDark" width="25" />
              <img v-if="!status1" :src="imageDeviceGray" width="25" />Agences Nord</span
            ><br />
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatus2"
              ><img v-if="status2" :src="imageDeviceGreen" width="25" />
              <img v-if="!status2" :src="imageDeviceGray" width="25" />Agences Sud</span
            ><br />
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatus3"
              ><img v-if="status3" :src="imageDevice" width="25" />
              <img v-if="!status3" :src="imageDeviceGray" width="25" />Techniciens</span
            ><br />
          </div>
        </l-control>

        <l-control>
        </l-control>
      </l-map>
    </div>
  </div>
</template>
<style scoped>
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.4);
}
@media (max-width: 760px) {
  .searchInput {
    top: 60px;
  }
}
</style>