var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loader == true)?_c('div',{staticClass:"loaderDiv d-flex justify-content-center align-items-center"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"label":"Loading...","variant":"primary"}})],1):_vm._e(),_c('div',{staticStyle:{"height":"100%"}},[_c('l-map',{ref:"map",staticStyle:{"height":"120vh"},attrs:{"zoom":_vm.mapZoom,"min-zoom":5,"options":{ zoomControl: false },"center":_vm.mapCenter},on:{"update:zoom":function($event){return _vm.updateZoom($event)}}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"subdomains":['mt0', 'mt1', 'mt2', 'mt3']}}),_c('l-layer-group',{attrs:{"visible":_vm.status1,"layerType":"overlay","name":"nord"}},_vm._l((_vm.nord),function(marker){return _c('l-marker',{key:marker.id,attrs:{"zIndexOffset":100,"draggable":false,"lat-lng":{ lat: marker.lat, lng: marker.lng },"icon":_vm.icon}},[_c('l-icon',{attrs:{"icon-anchor":_vm.iconAnchor,"icon-size":_vm.iconSize,"tooltipAnchor":_vm.tooltipAnchor,"icon-url":_vm.imageDevice}},[_c('img',{attrs:{"src":_vm.imageDeviceGreenDark}})]),_c('l-tooltip',{staticClass:"rounded text-secondary",attrs:{"options":{
              interactive: 'true',
              direction: 'top',
            }}},[_c('div',[_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v(_vm._s(_vm.$t("tooltips.name.text"))+" : ")]),_vm._v(" "+_vm._s(marker.name)),_c('br'),(marker.type)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Type : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.type ? marker.type : null)),(marker.type)?_c('br'):_vm._e(),(marker.address)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v(_vm._s(_vm.$t("tooltips.address.text"))+" : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.address ? marker.address : null)+" ")])])],1)}),1),_c('l-layer-group',{attrs:{"visible":_vm.status2,"layerType":"overlay","name":"sud"}},_vm._l((_vm.sud),function(marker){return _c('l-marker',{key:marker.id,attrs:{"zIndexOffset":100,"draggable":false,"lat-lng":{ lat: marker.lat, lng: marker.lng },"icon":_vm.icon}},[_c('l-icon',{attrs:{"icon-anchor":_vm.iconAnchor,"icon-size":_vm.iconSize,"tooltipAnchor":_vm.tooltipAnchor,"icon-url":_vm.imageDevice}},[_c('img',{attrs:{"src":_vm.imageDeviceGreen}})]),_c('l-tooltip',{staticClass:"rounded text-secondary",attrs:{"options":{
              interactive: 'true',
              direction: 'top',
            }}},[_c('div',[_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v(_vm._s(_vm.$t("tooltips.name.text"))+" : ")]),_vm._v(" "+_vm._s(marker.name)),_c('br'),(marker.type)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Type : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.type ? marker.type : null)),(marker.type)?_c('br'):_vm._e(),(marker.address)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v(_vm._s(_vm.$t("tooltips.address.text"))+" : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.address ? marker.address : null)+" ")])])],1)}),1),_c('l-layer-group',{attrs:{"visible":_vm.status3,"layerType":"overlay","name":"tech"}},_vm._l((_vm.tech),function(marker){return _c('l-marker',{key:marker.id,attrs:{"zIndexOffset":100,"draggable":false,"lat-lng":{ lat: marker.lat, lng: marker.lng },"icon":_vm.icon}},[_c('l-icon',{attrs:{"icon-anchor":_vm.iconAnchor,"icon-size":_vm.iconSize,"tooltipAnchor":_vm.tooltipAnchor,"icon-url":_vm.imageDevice}},[_c('img',{attrs:{"src":_vm.imageDevice}})]),_c('l-tooltip',{staticClass:"rounded text-secondary",attrs:{"options":{
              interactive: 'true',
              direction: 'top',
            }}},[_c('div',[_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v(_vm._s(_vm.$t("tooltips.name.text"))+" : ")]),_vm._v(" "+_vm._s(marker.name)),_c('br'),(marker.region)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Région : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.region ? marker.region : null)),(marker.region)?_c('br'):_vm._e(),(marker.equipe)?_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Equipe : ")]):_vm._e(),_vm._v(" "+_vm._s(marker.equipe ? marker.equipe : null)+" ")])])],1)}),1),_c('l-control',[_c('div',{staticStyle:{"background":"rgba(255, 255, 255, 0.4)"}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},on:{"click":_vm.changeStatus1}},[(_vm.status1)?_c('img',{attrs:{"src":_vm.imageDeviceGreenDark,"width":"25"}}):_vm._e(),(!_vm.status1)?_c('img',{attrs:{"src":_vm.imageDeviceGray,"width":"25"}}):_vm._e(),_vm._v("Agences Nord")]),_c('br'),_c('span',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},on:{"click":_vm.changeStatus2}},[(_vm.status2)?_c('img',{attrs:{"src":_vm.imageDeviceGreen,"width":"25"}}):_vm._e(),(!_vm.status2)?_c('img',{attrs:{"src":_vm.imageDeviceGray,"width":"25"}}):_vm._e(),_vm._v("Agences Sud")]),_c('br'),_c('span',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},on:{"click":_vm.changeStatus3}},[(_vm.status3)?_c('img',{attrs:{"src":_vm.imageDevice,"width":"25"}}):_vm._e(),(!_vm.status3)?_c('img',{attrs:{"src":_vm.imageDeviceGray,"width":"25"}}):_vm._e(),_vm._v("Techniciens")]),_c('br')])]),_c('l-control')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }