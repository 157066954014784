import { uuid } from "vue-uuid"
export const nord = [
  {
    id: uuid.v1(), "name": "Laaouamra",
    "type": "AB",
    "address": "216 LOT MABROUKA LAAOUAMRA",
    "lat": "35.065932634980356",
    "lng": "-6.092737494923908"
},
{
    id: uuid.v1(), "name": "Ouezzane",
    "type": "CL",
    "address": "QUARTIER HADDADINE RUE LALA ZOUAOUA N° 84",
    "lat": "34.79807844724429",
    "lng": "-5.575469551578203"
},
{
    id: uuid.v1(), "name": "Ain Dorrij",
    "type": "AB",
    "address": "ROUTE FES/OUEZZANE - AIN DORRIJ CENTRE",
    "lat": "34.616379756527586",
    "lng": "-5.291289061173094"
},
{
    id: uuid.v1(), "name": "Oued Laou",
    "type": "CL",
    "address": "AV HASSAN 2 OUED LAOU",
    "lat": "35.44809932440044",
    "lng": "-5.093816707387898"
},
{
    id: uuid.v1(), "name": "Imzouren",
    "type": "AB",
    "address": "225 BOULEVARD CASABLANCA IMZOUREN",
    "lat": "35.14462551307409",
    "lng": "-3.8515964946763863"
},
{
    id: uuid.v1(), "name": "Targuiste",
    "type": "AB",
    "address": "BOULEVARD DES FAR, TARGUIST CENTRE",
    "lat": "34.9376061379314",
    "lng": "-4.31600535736221"
},
{
    id: uuid.v1(), "name": "Beni Bouayach",
    "type": "AB",
    "address": "163 BD MOHAMED V BENI BOUAYACH 32050",
    "lat": "35.10681403763523",
    "lng": "-3.8413186995584883"
},
{
    id: uuid.v1(), "name": "Al Hoceima Biranzarane",
    "type": "AB",
    "address": "01, ANGLE RUE BOUKRAA ET BD BIRANZARANE",
    "lat": "35.24408881232025",
    "lng": "-3.9342354964193595"
},
{
    id: uuid.v1(), "name": "Bab Barad",
    "type": "AB",
    "address": "BAB BERRED CENTRE",
    "lat": "34.99831325925465",
    "lng": "-4.891045504468918"
},
{
    id: uuid.v1(), "name": "Ahfir",
    "type": "CL",
    "address": "7, Avenue Mohammed V-AHFIR",
    "lat": "34.953737601814744",
    "lng": "-2.0994863753273125"
},
{
    id: uuid.v1(), "name": "Ain Beni Mathar",
    "type": "CL",
    "address": "Complexe commercial Ain Aeni Mathar",
    "lat": "34.01567325833132",
    "lng": "-2.027319996586573"
},
{
    id: uuid.v1(), "name": "Aklim",
    "type": "CL",
    "address": "8, lotissement Saâda-AKLIM",
    "lat": "34.88592629087442",
    "lng": "-2.526426831909125"
},
{
    id: uuid.v1(), "name": "Beni Drar",
    "type": "CL",
    "address": "Av Hassan II-BENI DRAR",
    "lat": "34.86198052761261",
    "lng": "-1.9957878591015379"
},
{
    id: uuid.v1(), "name": "Berkane",
    "type": "CL",
    "address": "Rue Oued Deraa Hay Massira, B.P 201-BERKANE",
    "lat": "34.928957571843725",
    "lng": "-2.3343626245948217"
},
{
    id: uuid.v1(), "name": "Berkane Allaymoune",
    "type": "AB",
    "address": "Angle bvd Mohammed V et rue My Youssef- BERKANE",
    "lat": "34.924558499838646",
    "lng": "-2.335065318155562"
},
{
    id: uuid.v1(), "name": "Bouarfa",
    "type": "CL",
    "address": "Angle boulevard  Hassan II et Far bouarfa",
    "lat": "32.51911512129151",
    "lng": "-1.977299824407176"
},
{
    id: uuid.v1(), "name": "Driouech",
    "type": "CL",
    "address": "Av Hassan II n°175 Driouch",
    "lat": "34.975581431244336",
    "lng": "-3.3961739786299696"
},
{
    id: uuid.v1(), "name": "El Aioun",
    "type": "CL",
    "address": "9, Rue Bir Anzarane-EL AIOUNE",
    "lat": "34.58629881275074",
    "lng": "-2.5092320008553664"
},
{
    id: uuid.v1(), "name": "Figuig",
    "type": "AB",
    "address": "BD Hassan II Figuig",
    "lat": "32.12027691357135",
    "lng": "-1.2325212780301027"
},
{
    id: uuid.v1(), "name": "Jerada",
    "type": "AB",
    "address": "311, Bd. Hassan II n° 406 -Jerada-",
    "lat": "34.30964858048247",
    "lng": "-2.161759492295732"
},
{
    id: uuid.v1(), "name": "Midar",
    "type": "AB",
    "address": "blv Hassan II Midar Centre",
    "lat": "34.93745646193981",
    "lng": "-3.5367009515768766"
},
{
    id: uuid.v1(), "name": "Mont Aruit",
    "type": "CL",
    "address": "273, Boulevard Hassan II-NADOR",
    "lat": "35.011613874492475",
    "lng": "-3.0066152339078847"
},
{
    id: uuid.v1(), "name": "Nador Al Wahda",
    "type": "AB",
    "address": "Angle Route Segangan Ouled Mimoun-NADOR",
    "lat": "35.17221864036893",
    "lng": "-2.9369980309722177"
},
{
    id: uuid.v1(), "name": "Nador Ben Tayeb",
    "type": "AB",
    "address": "Bd Driss 1 Ben Tayeb Centre",
    "lat": "35.04511455371816",
    "lng": "-3.4564826126266146"
},
{
    id: uuid.v1(), "name": "Nador Méditerranée",
    "type": "AB",
    "address": "Route de Taouima  Nador",
    "lat": "35.13856097687134",
    "lng": "-2.92551460313211"
},
{
    id: uuid.v1(), "name": "Nador SAKIA EL HAMRA",
    "type": "AB",
    "address": "Bd, Sakia Al Hamra -Nador-",
    "lat": "35.17053445163941",
    "lng": "-2.9198582735876104"
},
{
    id: uuid.v1(), "name": "Oujda Angad",
    "type": "AB",
    "address": "1, Bvd Allal El Fassi - Bloc 63-OUJDA",
    "lat": "34.68231481509157",
    "lng": "-1.8850147521544072"
},
{
    id: uuid.v1(), "name": "Oujda El Fath",
    "type": "AB",
    "address": "Angle BD Allal Fassi et Rue F1 n° 1 Hay Al Wafaa Lazaret - Oujda",
    "lat": "34.68224924897313",
    "lng": "-1.88513253795401"
},
{
    id: uuid.v1(), "name": "Oujda Idriss Al Akbar",
    "type": "AB",
    "address": "01, Bd Idriss Al Akbar-Oujda",
    "lat": "34.678473717550425",
    "lng": "-1.9186965590288407"
},
{
    id: uuid.v1(), "name": "Oujda Isly",
    "type": "AB",
    "address": "96 bvd Mbarek El Bekkay rte de Taza OUJDA",
    "lat": "34.68187274473008",
    "lng": "-1.939246973689366"
},
{
    id: uuid.v1(), "name": "Oujda Jouhara",
    "type": "AB",
    "address": "Bd Malika El Farisi Hay El Jouhara Oujda",
    "lat": "34.6706811568604",
    "lng": "-1.933032523852491"
},
{
    id: uuid.v1(), "name": "Oujda Mohamed V",
    "type": "AB",
    "address": "Angle Bd Med V et Rue Amina Berhilia -Oujda",
    "lat": "34.69006835705559",
    "lng": "-1.9089154389613134"
},
{
    id: uuid.v1(), "name": "Oujda Zaitoune",
    "type": "AB",
    "address": "3,Angle BLV ABDERRAHIM BOUABID ET RUE A9- OUJDA",
    "lat": "34.690078138060244",
    "lng": "-1.9089233693137149"
},
{
    id: uuid.v1(), "name": "Saidia",
    "type": "AB",
    "address": "26 AV HASSAN II SAIDIA",
    "lat": "35.08500724507171",
    "lng": "-2.2219928353040026"
},
{
    id: uuid.v1(), "name": "Selouane",
    "type": "AB",
    "address": "Hay Al Wifak Angle Bd. Med V et rue n° 03 - Selouane-",
    "lat": "35.07212459760084",
    "lng": "-2.940593484342148"
},
{
    id: uuid.v1(), "name": "Talsint",
    "type": "CL",
    "address": "TALSINT CENTRE",
    "lat": "32.53610144731784",
    "lng": "-3.4438175411085785"
},
{
    id: uuid.v1(), "name": "Tendrara",
    "type": "CL",
    "address": "Angle BOULEVARD  Hassan II et Far Tendrara",
    "lat": "33.05468198429047",
    "lng": "-2.0004346289741273"
},
{
    id: uuid.v1(), "name": "Zaio",
    "type": "CL",
    "address": "17 Av Sidi Othmane B.P 198-ZAIO",
    "lat": "34.94132012899022",
    "lng": "-2.734711239034499"
},
{
    id: uuid.v1(), "name": "Zghenghen",
    "type": "AB",
    "address": "146-148 blv Med V  ZEGHANGHAN",
    "lat": "35.1529998485889",
    "lng": "-3.0060116638257863"
},
{
    id: uuid.v1(), "name": "Ouarzazate Oued Deraa",
    "type": "AB",
    "address": "Route de  Zagora  tarmigte  Ouarzazate",
    "lat": "30.899240525912624",
    "lng": "-6.909419296262569"
},
{
    id: uuid.v1(), "name": "Rich",
    "type": "AB",
    "address": "PLACE MED 5 RICH",
    "lat": "32.25700288372961",
    "lng": "-4.498225087710288"
},
{
    id: uuid.v1(), "name": "Kelaa M Gouna",
    "type": "AB",
    "address": "AV MED V KELAAT M'GOUNA",
    "lat": "31.24130918719229",
    "lng": "-6.128203629586056"
},
{
    id: uuid.v1(), "name": "Ouarzazate Med V",
    "type": "AB",
    "address": "12 AVENUE MED V OURZAZATE",
    "lat": "30.920386808779476",
    "lng": "-6.91696339758939"
},
{
    id: uuid.v1(), "name": "Tinghir",
    "type": "AB",
    "address": "AVENUE MOHAMED V TINGHIR",
    "lat": "31.51935572598373",
    "lng": "-5.5345111575169925"
},
{
    id: uuid.v1(), "name": "Boumalne Dades",
    "type": "AB",
    "address": "AVENUE MED BOUMALNE DADES",
    "lat": "31.368903061742895",
    "lng": "-5.988296480705998"
},
{
    id: uuid.v1(), "name": "Zagora",
    "type": "AB",
    "address": "AVENUE MOHAMED V ZAGORA",
    "lat": "30.328398015287664",
    "lng": "-5.837983490295813"
},
{
    id: uuid.v1(), "name": "Rissani My Ali Cherif",
    "type": "AB",
    "address": "AVENUE MY ALICHERIF RISSANI",
    "lat": "31.279795664714495",
    "lng": "-4.258717770730357"
},
{
    id: uuid.v1(), "name": "Errachidia Targa",
    "type": "AB",
    "address": "AVENUE MOHAMED VI ERRACHIDIA",
    "lat": "31.929174519665075",
    "lng": "-4.443747338480257"
},
{
    id: uuid.v1(), "name": "Tinjedad",
    "type": "AB",
    "address": "AVENUE HASSAN II TINEJDAD",
    "lat": "31.513018058636707",
    "lng": "-5.02315701291007"
},
{
    id: uuid.v1(), "name": "Errachidia Youssef Ibn Tachafine",
    "type": "AB",
    "address": "ANGLE RUE YOUSSEF IBN TACHFINE ET RUE TARGA ERRACHIDIA",
    "lat": "31.932793847236905",
    "lng": "-4.428239553953014"
},
{
    id: uuid.v1(), "name": "Midelt",
    "type": "CL",
    "address": "AVENUE MED VI ROUTE MEKNES MIDELT",
    "lat": "32.67996159447857",
    "lng": "-4.730000697304726"
},
{
    id: uuid.v1(), "name": "Boumia",
    "type": "CL",
    "address": "RUE DE LA MARCHE VERTE BOUMIA",
    "lat": "32.73044841345385",
    "lng": "-5.099313994969663"
},
{
    id: uuid.v1(), "name": "El Jorf",
    "type": "CL",
    "address": "AVENUE MED V EL  JORF",
    "lat": "31.486289333853993",
    "lng": "-4.399783435340619"
},
{
    id: uuid.v1(), "name": "Zaida",
    "type": "CL",
    "address": "ZAIDA CENTRE",
    "lat": "32.68870345693876",
    "lng": "-4.729958505597265"
},
{
    id: uuid.v1(), "name": "Boudnib",
    "type": "CL",
    "address": "BLOC 16 N°76 HAY EL MASSIRA BOUDNIB",
    "lat": "31.950502860930243",
    "lng": "-3.5970550288435557"
},
{
    id: uuid.v1(), "name": "GOULMIMA",
    "type": "CL",
    "address": "AVENUE HASSAN II GOULMIMA",
    "lat": "31.69089816240221",
    "lng": "-4.954236838976659"
},
{
    id: uuid.v1(), "name": "Mkansa",
    "type": "CL",
    "address": "M'KANSA CENTRE CERCLE KARIA BA MED",
    "lat": "34.302373006149416",
    "lng": "-5.443348127053685"
},
{
    id: uuid.v1(), "name": "Tissa",
    "type": "CL",
    "address": "HAY DAKHLA TISSA",
    "lat": "34.285886983260916",
    "lng": "-4.6631489921441265"
},
{
    id: uuid.v1(), "name": "BHALIL",
    "type": "CL",
    "address": "N ° 45 AV LA MARCHE VERTE BHALIL",
    "lat": "33.85511191382921",
    "lng": "-4.871024221277221"
},
{
    id: uuid.v1(), "name": "Ribat El Kheir",
    "type": "AB",
    "address": "G2 Quartier El Mahatta Ribat el kheir 31350 Maroc",
    "lat": "33.826620153614485",
    "lng": "-4.412318195989654"
},
{
    id: uuid.v1(), "name": "Meknès Ibn Khaldoun",
    "type": "AB",
    "address": "N°176 AV DES FAR VN MEKNES",
    "lat": "33.89748395642336",
    "lng": "-5.538619022893899"
},
{
    id: uuid.v1(), "name": "Tahala",
    "type": "CL",
    "address": "AVENUE ATLAS TAHLA",
    "lat": "34.048687752982886",
    "lng": "-4.423073732660313"
},
{
    id: uuid.v1(), "name": "Azrou",
    "type": "CL",
    "address": "Crédit Agricole du Maroc Route de Khénifra- Azrou",
    "lat": "33.44142696809936",
    "lng": "-5.224812367386293"
},
{
    id: uuid.v1(), "name": "Boufekrane",
    "type": "CL",
    "address": "N 112 LOT TARIK ,Av la marche verte Boufekrane",
    "lat": "33.75908454745567",
    "lng": "-5.485235075746436"
},
{
    id: uuid.v1(), "name": "TAOUNATE Hassan II",
    "type": "AB",
    "address": "HAY AL AMAL TAOUNATE",
    "lat": "34.548185855752685",
    "lng": "-4.6433879558452436"
},
{
    id: uuid.v1(), "name": "Sebaâ Ayoune",
    "type": "CL",
    "address": "BOULVARD HASSAN II 51150 SEBAA AIYOUNE",
    "lat": "33.90294275777853",
    "lng": "-5.3752313608186775"
},
{
    id: uuid.v1(), "name": "Agourai",
    "type": "CL",
    "address": "AV  DE LA MARCHE VERTE  AGOURAI  CENTRE",
    "lat": "33.644620850291695",
    "lng": "-5.590368229885723"
},
{
    id: uuid.v1(), "name": "Outat AI Haj",
    "type": "AB",
    "address": "PLACE LA MOSQUEE, AV HASSAN 2 CENTREOUTAT EL HAJ",
    "lat": "33.344681082579385",
    "lng": "-3.7039932486909657"
},
{
    id: uuid.v1(), "name": "Meknès",
    "type": "CL",
    "address": "RESIDENCE YAHYA IMMEUBLE C BAB LAKHMISS MEKNES",
    "lat": "33.89801917952889",
    "lng": "-5.5485757634096275"
},
{
    id: uuid.v1(), "name": "Aknoul",
    "type": "AB",
    "address": "14 LOT AL FATH BD AL MASSIRA AKNOUL CENTRE",
    "lat": "34.653858913753474",
    "lng": "-3.8655067240634553"
},
{
    id: uuid.v1(), "name": "Ain Taoujdate",
    "type": "CL",
    "address": "AV IBN SINA AIN TAOUJDATE",
    "lat": "33.93750559773401",
    "lng": "-5.212510857543796"
},
{
    id: uuid.v1(), "name": "RAS TABOUDA",
    "type": "CL",
    "address": "Route FES-OUJDA  Centre RAS TABOUDA",
    "lat": "34.002703261209746",
    "lng": "-4.712200008616568"
},
{
    id: uuid.v1(), "name": "Fes Bensouda",
    "type": "AB",
    "address": "AV DE L'ECOLE BENSOUDA FES",
    "lat": "34.01541301742772",
    "lng": "-5.051398019062888"
},
{
    id: uuid.v1(), "name": "Timhdite",
    "type": "CL",
    "address": "CENTRE TIMAHDITE",
    "lat": "33.52276274119921",
    "lng": "-5.111066899280742"
},
{
    id: uuid.v1(), "name": "Ifrane",
    "type": "AB",
    "address": "HAY RIAD IFRANE",
    "lat": "33.52878051009254",
    "lng": "-5.10854913581339"
},
{
    id: uuid.v1(), "name": "Meknès Kortoba",
    "type": "AB",
    "address": "LOT 1 TR 3 MARJANE 1 MEKNES",
    "lat": "33.85927894534515",
    "lng": "-5.55613047116769"
},
{
    id: uuid.v1(), "name": "Meknès Zitoune",
    "type": "AB",
    "address": "BD ABDELKRIM EL KHATTABI ZITOUNE MEKNES",
    "lat": "33.87054945347978",
    "lng": "-5.555469641151566"
},
{
    id: uuid.v1(), "name": "El Menzel",
    "type": "CL",
    "address": "AV MED 5",
    "lat": "33.83674903573228",
    "lng": "-4.5469810582143"
},
{
    id: uuid.v1(), "name": "Meknès Med V",
    "type": "AB",
    "address": "22,IMM SETISSI AVENUE MED V MEKNES",
    "lat": "33.89505055190306",
    "lng": "-5.555509697572989"
},
{
    id: uuid.v1(), "name": "Oued Amlil",
    "type": "CL",
    "address": "OUED AMLIL CENTRE",
    "lat": "34.192803113207354",
    "lng": "-4.261802488450557"
},
{
    id: uuid.v1(), "name": "Meknes Sidi Bouzekri",
    "type": "AB",
    "address": "LOT AL YOSR RTE D EL HAJEB MEKNES",
    "lat": "33.895134409940994",
    "lng": "-5.554942444487343"
},
{
    id: uuid.v1(), "name": "MEKNES TOULAL",
    "type": "AB",
    "address": "AV 1 N° 52 TOULAL-MEKNES",
    "lat": "33.8940243933685",
    "lng": "-5.5634401447360755"
},
{
    id: uuid.v1(), "name": "Boulmane",
    "type": "CL",
    "address": "AV HASSAN 2 CENTRE BOULEMANE",
    "lat": "33.36619916274573",
    "lng": "-4.732070460401024"
},
{
    id: uuid.v1(), "name": "Rhafsai",
    "type": "AB",
    "address": "AV HASSAN II RHAFSAI",
    "lat": "34.63123240209249",
    "lng": "-4.911721965235607"
},
{
    id: uuid.v1(), "name": "Meknes Ouislane",
    "type": "AB",
    "address": "N° 40 AV EL MASSIRA OUISLANE MEKNES",
    "lat": "33.895219119042345",
    "lng": "-5.55598035679018"
},
{
    id: uuid.v1(), "name": "Meknes Marjane",
    "type": "AB",
    "address": "BV MED VI IMM 44 MARJANE 2 MEKNES",
    "lat": "33.86134443246129",
    "lng": "-5.57758908288321"
},
{
    id: uuid.v1(), "name": "Khemisset",
    "type": "CL",
    "address": "N° 374 AV MED V KHEMISSET",
    "lat": "33.83067101190374",
    "lng": "-6.0976011281649"
},
{
    id: uuid.v1(), "name": "Salé Tabriquet",
    "type": "AB",
    "address": "4 AV MED V IM AMMAR TABRIQUET SALE",
    "lat": "34.04658172233686",
    "lng": "-6.80405732750787"
},
{
    id: uuid.v1(), "name": "Oulmès",
    "type": "CL",
    "address": "QUARTIER ADMINISTRATIF ROUTE DE KHÉNIFRA",
    "lat": "33.441357515894325",
    "lng": "-5.224900515398158"
},
{
    id: uuid.v1(), "name": "Sidi Allal Bahraoui",
    "type": "AB",
    "address": "LOT 279 LOTISSEMENT CHANA ROUTE PRINCILE DE RABAT SIDI ALLAL BAHRAOUI",
    "lat": "34.01099553824457",
    "lng": "-6.533085946397612"
},
{
    id: uuid.v1(), "name": "Skhirat Hassan II",
    "type": "AB",
    "address": "6 LOTISSEMENT MAATOUKA, B.P 4392 SKHIRAT",
    "lat": "33.88924663635654",
    "lng": "-6.971550855617899"
},
{
    id: uuid.v1(), "name": "Souk El Arbaa",
    "type": "CL",
    "address": "ANGLE AV.ABDELMOUMEN BEN ALI ET BIR ANZARANE SOUK EL ARBAA GHARB",
    "lat": "34.684836870203625",
    "lng": "-6.007156800941967"
},
{
    id: uuid.v1(), "name": "Had Kourt",
    "type": "CL",
    "address": "HAD KOURT  CENTRE",
    "lat": "34.61509012346926",
    "lng": "-5.734103816292173"
},
{
    id: uuid.v1(), "name": "Sidi Kacem",
    "type": "AB",
    "address": "47 RUE N° 4 NOUVEAU QUARTIER SIDI KACEM",
    "lat": "34.2250407662159",
    "lng": "-5.712786078946969"
},
{
    id: uuid.v1(), "name": "Lalla Mimouna",
    "type": "CL",
    "address": "LALLA MIMOUNA CENTRE",
    "lat": "34.84764524571474",
    "lng": "-6.070118577833625"
},
{
    id: uuid.v1(), "name": "Machraa Bel Ksiri",
    "type": "CL",
    "address": "IMMB LAASSAL AV BIR ANZARANE",
    "lat": "34.57631023750843",
    "lng": "-5.954236073493343"
},
{
    id: uuid.v1(), "name": "Jorf El Melha",
    "type": "CL",
    "address": "CENTRE JORF EL MELHA ROUTE BARRAGE AL OUAHDA JORF EL MELHA",
    "lat": "34.489661437461805",
    "lng": "-5.509709297705406"
},
{
    id: uuid.v1(), "name": "Khénichat",
    "type": "CL",
    "address": "BD HASSAN II ROUTE DE JORF EL MELHA",
    "lat": "34.440146907209936",
    "lng": "-5.680253762176221"
},
{
    id: uuid.v1(), "name": "Sidi Slimane Hassan II",
    "type": "AB",
    "address": "AV HASSAN II HAY ESSALAM BLOC D N°2",
    "lat": "34.25958031349313",
    "lng": "-5.92245032154529"
}
]

export const sud = [
  {
    id: uuid.v1(), "name": "CASA TARIK EL KHAIR",
    "type": "AB",
    "address": "188 RUE 9 AV MBAREK BEN BOUBKER SIDI BERNOUSSI CASABLANCA",
    "lat": "33.609342804008556",
    "lng": "-7.497629183253662"
},
{
    id: uuid.v1(), "name": "CASA BOUCHAIB DOUKKALI",
    "type": "AB",
    "address": "593 BD BOUCHAIB DOUKKALI DRISSIA 2 CASABLANCA",
    "lat": "33.57385963400231",
    "lng": "-7.578191032934763"
},
{
    id: uuid.v1(), "name": "CASA TOUR ATLAS",
    "type": "AB",
    "address": "IMMEUBLE TOUR ATLAS PLACE ZELLAKA",
    "lat": "33.59725409808565",
    "lng": "-7.609932335390288"
},
{
    id: uuid.v1(), "name": "CASA RYAD OULFA",
    "type": "AB",
    "address": "1 OP GH 12 RYAD OULFA  CASA",
    "lat": "33.556225951793415",
    "lng": "-7.681247153154386"
},
{
    id: uuid.v1(), "name": "CASA MEDIOUNA",
    "type": "AB",
    "address": "LOT TISSIR 138 CASA MEDIOUNA",
    "lat": "33.45509739651819",
    "lng": "-7.5155416041815615"
},
{
    id: uuid.v1(), "name": "CASA OULED HADDOU",
    "type": "AB",
    "address": "RES MAWLID 6 ROUTE SIDIMAAROUF CASA",
    "lat": "33.57508929931192",
    "lng": "-7.6475333335687266"
},
{
    id: uuid.v1(), "name": "MOHAMMEDIA SEBTA",
    "type": "AB",
    "address": "RUE SEBTA MOHAMMEDIA",
    "lat": "33.694098633503984",
    "lng": "-7.378868309963912"
},
{
    id: uuid.v1(), "name": "CASA PORT",
    "type": "AB",
    "address": "PLACE ZELLAKA TOUR ATLAS (ADRESSE PROVISOIRE)",
    "lat": "33.597249714487226",
    "lng": "-7.609931349200113"
},
{
    id: uuid.v1(), "name": "CASA GRANDE CEINTURE",
    "type": "AB",
    "address": "LOT AL MOUAHIDINE BD LA GRANDE CEINTURE N° 342 - CASABLANCA",
    "lat": "33.58777253988857",
    "lng": "-7.545965775662218"
},
{
    id: uuid.v1(), "name": "CASA MOHAMMED VI",
    "type": "AB",
    "address": "496 BD MED VI CASABLANCA",
    "lat": "33.57302505310062",
    "lng": "-7.59826138055448"
},
{
    id: uuid.v1(), "name": "CASA EL FALAH",
    "type": "AB",
    "address": "4, LOT FALAH 2 - MOHAMMEDIA",
    "lat": "33.69957702352854",
    "lng": "-7.363857832746071"
},
{
    id: uuid.v1(), "name": "MOHAMMEDIA HASSAN II",
    "type": "AB",
    "address": "AVENUE HASSAN II, RESIDENCE DEMNATE, MOHAMMEDIA",
    "lat": "33.6939526057426",
    "lng": "-7.391509898342169"
},
{
    id: uuid.v1(), "name": "BOUZNIKA",
    "type": "AB",
    "address": "AVENUE HASSAN 2 RÉSIDENCE YASMINE",
    "lat": "33.789273943974536",
    "lng": "-7.1589067435962175"
},
{
    id: uuid.v1(), "name": "BERRECHID",
    "type": "AB",
    "address": "28 BD MED V BERRECHID",
    "lat": "33.27021056075836",
    "lng": "-7.585577194309267"
},
{
    id: uuid.v1(), "name": "MOHAMMEDIA EL ALIA",
    "type": "AB",
    "address": "12 AVENUE MOKHTAR SOUSSI EL ALIA MOHAMMEDIA",
    "lat": "33.676935591664105",
    "lng": "-7.384693961523878"
},
{
    id: uuid.v1(), "name": "BEN AHMED",
    "type": "CL",
    "address": "7 Rue Hansali Ben Ahmed",
    "lat": "33.06637427420365",
    "lng": "-7.244695383432842"
},
{
    id: uuid.v1(), "name": "SIDI HAJAJ",
    "type": "CL",
    "address": "BD MOHAMED V SIDI HAJAJ",
    "lat": "32.9119790581539",
    "lng": "-7.263152311212733"
},
{
    id: uuid.v1(), "name": "EL BROUJ",
    "type": "CL",
    "address": "BD MOHAMED 5 EL BROUJ",
    "lat": "32.50546429882587",
    "lng": "-7.191176449437404"
},
{
    id: uuid.v1(), "name": "OULED SAID",
    "type": "CL",
    "address": "Ouled Said Centre",
    "lat": "32.96768636533155",
    "lng": "-7.813452094048186"
},
{
    id: uuid.v1(), "name": "OULED ABBOU",
    "type": "CL",
    "address": "169 Avenue Hassan II Ouled Abbou",
    "lat": "33.11309128429786",
    "lng": "-7.932451479881985"
},
{
    id: uuid.v1(), "name": "BOUZNIKA",
    "type": "CL",
    "address": "204, Bd Bir Anzarane Bouznika",
    "lat": "33.78650481879318",
    "lng": "-7.1452982685178705"
},
{
    id: uuid.v1(), "name": "BERRECHID",
    "type": "CL",
    "address": "BD MOULAY EL HASAN BERRECHID",
    "lat": "33.26611462660381",
    "lng": "-7.581289347890786"
},
{
    id: uuid.v1(), "name": "HAD SOUALEM",
    "type": "CL",
    "address": "N° 211 LOT ANHY SAHEL",
    "lat": "33.4217501205794",
    "lng": "-7.85451401625386"
},
{
    id: uuid.v1(), "name": "BENSLIMANE",
    "type": "CL",
    "address": "AVENUE OUED ZEM RUE TARIK IBN ZIAD",
    "lat": "33.611797979619865",
    "lng": "-7.1226295200521985"
},
{
    id: uuid.v1(), "name": "EL GARA",
    "type": "CL",
    "address": "BOULEVARD IMAME ABDOU EL GARA",
    "lat": "33.27811212093735",
    "lng": "-7.229106903979256"
},
{
    id: uuid.v1(), "name": "BERRECHID MASSIRA",
    "type": "AB",
    "address": "Lot naima n° 81 MASSIRA BERRCHID",
    "lat": "33.25986105834331",
    "lng": "-7.572014823369152"
},
{
    id: uuid.v1(), "name": "OUALIDIA",
    "type": "AB",
    "address": "Hay Moulay Abdesslam, Route Régionale n° 301, Oualidiya",
    "lat": "32.73630846052432",
    "lng": "-9.027978521494918"
},
{
    id: uuid.v1(), "name": "KHEMISS ZEMAMRA",
    "type": "CL",
    "address": "Avenue HASSAN II ROUTE TNIN GHARBIA KHEMISS ZEMAMRA",
    "lat": "32.62357406019909",
    "lng": "-8.706279782081122"
},
{
    id: uuid.v1(), "name": "SIDI SMAIL",
    "type": "CL",
    "address": "IMMEUBLE SOUJDA ROUTE KHEMISSE ZEMAMERA",
    "lat": "32.82339514476353",
    "lng": "-8.513071471410324"
},
{
    id: uuid.v1(), "name": "HAD OULED FREJ",
    "type": "CL",
    "address": "HAY AL MASSEJID ROUTE SETTAT OD FREJ",
    "lat": "32.951868768070455",
    "lng": "-8.217636244569896"
},
{
    id: uuid.v1(), "name": "LARBAA LAOUNATE",
    "type": "CL",
    "address": "Route Had Ouled Frej Aounate - Centre",
    "lat": "32.747270863456684",
    "lng": "-8.196211363679446"
},
{
    id: uuid.v1(), "name": "OULED AMRANE",
    "type": "CL",
    "address": "centre ouled amrane",
    "lat": "32.37099025588069",
    "lng": "-8.538736189369338"
},
{
    id: uuid.v1(), "name": "DCHEIRA",
    "type": "APP",
    "address": "IMMEUBLE CHOUKRI BD BIRANZARAN -LOT ESSAADA - DCHEIRA LA PERGOULA-INEZGANE",
    "lat": "30.3704282427324",
    "lng": "-9.53997903390269"
},
{
    id: uuid.v1(), "name": "TIZNIT VILLE NOUVELLE",
    "type": "APP",
    "address": "N 30 BD 20 AOUT VILLE NOUVELLE 85000 TIZNIT",
    "lat": "29.695105777777822",
    "lng": "-9.73246780156353"
},
{
    id: uuid.v1(), "name": "SIDI IFNI",
    "type": "CL",
    "address": "IMMEUBLE  ABOUSSAHL  N° 3, RUE MED  BEN ABDELLAH   SIDI IFNI",
    "lat": "29.377225249168024",
    "lng": "-10.169658568841333"
},
{
    id: uuid.v1(), "name": "SEBT EL GUERDANE",
    "type": "CL",
    "address": "Imm,Chouikh Hay Safsaf El Guerdane",
    "lat": "30.377462058514414",
    "lng": "-9.021147551041018"
},
{
    id: uuid.v1(), "name": "DRARGUA",
    "type": "CL",
    "address": "IMM NAIT AHMED BACHIR DRARGA",
    "lat": "30.379156476236922",
    "lng": "-9.479821565272736"
},
{
    id: uuid.v1(), "name": "LAAYOUNE SAKIA HAMRA",
    "type": "APP",
    "address": "AV MED 6 RUE TABBOUK IMM ANSARRI LAAYOUNE",
    "lat": "27.148927464457614",
    "lng": "-13.196670953707772"
},
{
    id: uuid.v1(), "name": "HAD BELFAA",
    "type": "CL",
    "address": "Immeuble Dakkous Centre Belfaa",
    "lat": "29.541553978941707",
    "lng": "-9.696532941160186"
},
{
    id: uuid.v1(), "name": "TIZNIT AL QUODS",
    "type": "APP",
    "address": "N°09 PLACE EL YOUSSOUFIA TIZNIT",
    "lat": "29.695076285192034",
    "lng": "-9.732478151581507"
},
{
    id: uuid.v1(), "name": "LAAYOUNE AL FARAH",
    "type": "APP",
    "address": "AV MED SALEM BAIDA SOUK JMAL LAAYOUNE",
    "lat": "27.163455322335356",
    "lng": "-13.213279557773198"
},
{
    id: uuid.v1(), "name": "KHMISS AIT AMIRA",
    "type": "CL",
    "address": "IMM BOUAA RT BIOUGRA  AIT AMIRA CHTOUKA AIT BAHA",
    "lat": "30.175221015891772",
    "lng": "-9.486495217645679"
},
{
    id: uuid.v1(), "name": "TAN TAN",
    "type": "APP",
    "address": "AV HASSAN TAN TAN",
    "lat": "28.43813296367576",
    "lng": "-11.101112810889761"
},
{
    id: uuid.v1(), "name": "LAAYOUNE AL MASSIRA",
    "type": "AE",
    "address": "BOULEVARD MEKKA, IMM OBOHOY, LAAYOUNE",
    "lat": "27.15698906083227",
    "lng": "-13.202585011305672"
},
{
    id: uuid.v1(), "name": "GUELMIM BENTACHFINE",
    "type": "APP",
    "address": "Angle Av Hassan II et Youssef Ben tachfine",
    "lat": "28.98701263269641",
    "lng": "-10.05681746851537"
},
{
    id: uuid.v1(), "name": "LAAYOUNE ELMARSA",
    "type": "APP",
    "address": "ANGLE AVENUE ENNAHDA ET AVENUE AHMED BELAFERJI",
    "lat": "27.097431645588266",
    "lng": "-13.415253724995276"
},
{
    id: uuid.v1(), "name": "DAKHLA OUED EDDAHAB",
    "type": "AE",
    "address": "ANGLE AV ABDERRAHIM BOUABID ET RUE TANSSIFT DAKHLA",
    "lat": "23.688648972016416",
    "lng": "-15.940303449085514"
},
{
    id: uuid.v1(), "name": "OULED BERHIL",
    "type": "CL",
    "address": "centre ouled berhil",
    "lat": "30.636609781518278",
    "lng": "-8.478606194538665"
},
{
    id: uuid.v1(), "name": "BOUJDOUR",
    "type": "APP",
    "address": "BOULEVARD HASSAN II BOUJDOUR",
    "lat": "26.12421174741412",
    "lng": "-14.484463476848331"
},
{
    id: uuid.v1(), "name": "INEZGANE KASBAH",
    "type": "APP",
    "address": "ANGLE RUE KABAH ET AV MOHAMED V INEZHANE",
    "lat": "30.355701550966813",
    "lng": "-9.53315790261626"
},
{
    id: uuid.v1(), "name": "OULAD TAIMA",
    "type": "APP",
    "address": "ANGLE AV MED 6 ET AV HOURIA OULED TEIMA",
    "lat": "30.391496307329685",
    "lng": "-9.217408295932778"
},
{
    id: uuid.v1(), "name": "BIOUGRA",
    "type": "CL",
    "address": "Imm HIDA Av Hassan II Biougra",
    "lat": "30.217555794474155",
    "lng": "-9.371724873801341"
},
{
    id: uuid.v1(), "name": "TEMSIA",
    "type": "CL",
    "address": "Imm. ElBouchtaoui - Temsia Province Inzegane Ait melloul",
    "lat": "30.365582362463524",
    "lng": "-9.41546235387959"
},
{
    id: uuid.v1(), "name": "LAQLIAA",
    "type": "APP",
    "address": "CENTRE LAQLIAA  ROUTE AIT MELLOUL -BIOUGRA",
    "lat": "30.287277962779655",
    "lng": "-9.451903688835973"
},
{
    id: uuid.v1(), "name": "TATA",
    "type": "APP",
    "address": "AV MOHAMED5 TATA",
    "lat": "29.747933673335197",
    "lng": "-7.973255051207074"
},
{
    id: uuid.v1(), "name": "TALIOUINE",
    "type": "CL",
    "address": "AV HASSAN II TALIOUINE",
    "lat": "30.5339886862655",
    "lng": "-7.931607105066367"
},
{
    id: uuid.v1(), "name": "AIT IAZZA",
    "type": "CL",
    "address": "Route nationale N° 10 Ait Iaaza Taroudant",
    "lat": "30.5045060102369",
    "lng": "-8.798206784668896"
},
{
    id: uuid.v1(), "name": "TAROUDANT BAB TARGHOUNTE",
    "type": "APP",
    "address": "AV MOH 05 BAB TARGHOUNT 83000 TAROUDANT",
    "lat": "30.46960544096095",
    "lng": "-8.887427137415894"
},
{
    id: uuid.v1(), "name": "SIDI MOUSSA EL HAMRI",
    "type": "CL",
    "address": "CENTRE SIDI MOUSSA LHAMRI CERCLE SIDI MOUSSA LHAMRI PROVINCE TAROUDANT",
    "lat": "30.471749870096268",
    "lng": "-9.158065089669547"
},
{
    id: uuid.v1(), "name": "GUELMIM BIR ANZARANE",
    "type": "APP",
    "address": "Av med VI Guelmim",
    "lat": "28.988075222798003",
    "lng": "-10.057671422395778"
},
{
    id: uuid.v1(), "name": "AOULOUZ",
    "type": "CL",
    "address": "IMM JELLALI AV MOHAMED V AOULOUZ",
    "lat": "30.67252464021367",
    "lng": "-8.17873544682576"
},
{
    id: uuid.v1(), "name": "Imintanout",
    "type": "AB",
    "address": "Route D'Agadir Sidi  Ali  Ishaq Imintanout",
    "lat": "31.173373044282314",
    "lng": "-8.852439221555526"
},
{
    id: uuid.v1(), "name": "Jemaa Shaim",
    "type": "CL",
    "address": "BD. MOHAMED V  46100 JEMAA SHAIM",
    "lat": "32.34507373255376",
    "lng": "-8.845050725720938"
},
{
    id: uuid.v1(), "name": "Youssoufia",
    "type": "AB",
    "address": "84, Bd Bir Anzarane Youssoufia Centre",
    "lat": "32.23787910105998",
    "lng": "-8.545520415787777"
},
{
    id: uuid.v1(), "name": "Safi Miftah Lkhir",
    "type": "AB",
    "address": "Route Had Hrara, Sanaa 1, Safi",
    "lat": "32.31237727515433",
    "lng": "-9.22998743193271"
},
{
    id: uuid.v1(), "name": "Had Hrara",
    "type": "CL",
    "address": "ROUTE HAD HRARA,SAFI",
    "lat": "32.438893081950326",
    "lng": "-9.132307561838985"
},
{
    id: uuid.v1(), "name": "SAFI ZAOUIA",
    "type": "AB",
    "address": "Zaouit Sidi Wassal Avenue El Ouazzani Safi",
    "lat": "32.2614275747948",
    "lng": "-9.24853817098923"
},
{
    id: uuid.v1(), "name": "ESSAOUIRA EL BORJ",
    "type": "AB",
    "address": "106 AL BORJ 1 - ESSAOUIRA",
    "lat": "31.502587342497424",
    "lng": "-9.759083008245861"
},
{
    id: uuid.v1(), "name": "SAFI AZIB DARII",
    "type": "AB",
    "address": "Avenue Abdeslam M'jid ang . rue Ibn Battouta q.Azib Drai,Safi-Maroc",
    "lat": "32.29416084973777",
    "lng": "-9.222227857004805"
},
{
    id: uuid.v1(), "name": "OUNAGHA",
    "type": "CL",
    "address": "Douar Ait hamed commune ounagha centre",
    "lat": "31.532589099472812",
    "lng": "-9.549712414455374"
},
{
    id: uuid.v1(), "name": "El Ksiba",
    "type": "CL",
    "address": "Bd de la Marche Verte",
    "lat": "32.566089799180006",
    "lng": "-6.032576008728067"
},
{
    id: uuid.v1(), "name": "Khenifra",
    "type": "CL",
    "address": "Bd. de la Marche Verte Khenifra ",
    "lat": "32.93875322995957",
    "lng": "-5.665589920995432"
},
{
    id: uuid.v1(), "name": "My Bouazza",
    "type": "CL",
    "address": "Route Oued Zem-Centre Moulay Bouazza.",
    "lat": "33.22575450530376",
    "lng": "-6.197445605745909"
},
{
    id: uuid.v1(), "name": "M'Rirt",
    "type": "CL",
    "address": "Agence M'Rirt 6, bd Mohamed V Mrirt",
    "lat": "33.16261475536311",
    "lng": "-5.566448204445558"
},
{
    id: uuid.v1(), "name": "El Kbab",
    "type": "CL",
    "address": "av. Hassan II hay Takaddoune El Kebab",
    "lat": "32.741501122668545",
    "lng": "-5.525652035852497"
},
{
    id: uuid.v1(), "name": "Zaouat Cheikh",
    "type": "CL",
    "address": "Bd Mohammed V Zaouiat Cheikh",
    "lat": "32.64717080703454",
    "lng": "-5.9175030591990385"
},
{
    id: uuid.v1(), "name": "Beni Mellal Zaitoune",
    "type": "AB",
    "address": "84 bd Mohamed V Beni Mellal - Maroc",
    "lat": "32.3389878960614",
    "lng": "-6.34622802557782"
},
{
    id: uuid.v1(), "name": "Ouled Yach",
    "type": "CL",
    "address": "Province Beni Mellal sur l’axe routier Beni Mellal, Marrakech",
    "lat": "32.43748738564594",
    "lng": "-6.327875372913921"
},
{
    id: uuid.v1(), "name": "Ouled Mbarek",
    "type": "CL",
    "address": "Province Beni Mellal sur l’axe routier Beni Mellal, Fès",
    "lat": "32.28156899300983",
    "lng": "-6.456311141568027"
},
{
    id: uuid.v1(), "name": "Kasba Tadla",
    "type": "AB",
    "address": "Angle Bd Zerktouni et Rue Tarik Ibnou Ziad",
    "lat": "32.59586562405379",
    "lng": "-6.262438268990534"
},
{
    id: uuid.v1(), "name": "Ouled Youssef",
    "type": "AB",
    "address": "Centre Oulad Saïd Oulad Said - Maroc",
    "lat": "30.027160052902236",
    "lng": "-5.512611569312553"
},
{
    id: uuid.v1(), "name": "Souk Sebt",
    "type": "CL",
    "address": "rue El hansali Pam II Souk Sebt Aït Rahou - Maroc",
    "lat": "32.29756119350719",
    "lng": "-6.705822593023696"
},
{
    id: uuid.v1(), "name": "Ouaouizerth",
    "type": "CL",
    "address": "Ouaouizerth Centre Ouaouizarth - Maroc",
    "lat": "32.15681504887003",
    "lng": "-6.356282464419983"
},
{
    id: uuid.v1(), "name": "Bzou",
    "type": "CL",
    "address": "bd Mohammed V hay El Madrassa Bzou - Maroc",
    "lat": "32.08839924680628",
    "lng": "-7.045261589840565"
},
{
    id: uuid.v1(), "name": "Boujniba",
    "type": "CL",
    "address": "4 Mohamed V Boujniba - Maroc",
    "lat": "32.89944116897336",
    "lng": "-6.78130553649627"
},
{
    id: uuid.v1(), "name": "Demnate",
    "type": "CL",
    "address": "Av Mohammed V Ait Ouganoune Demnate - Maroc",
    "lat": "31.734156009057678",
    "lng": "-7.004827601169562"
},
{
    id: uuid.v1(), "name": "Azilal",
    "type": "AB",
    "address": "Av. Hassan II Azilal - Maroc",
    "lat": "31.963254837638527",
    "lng": "-6.5672376036283"
},
{
    id: uuid.v1(), "name": "Had Bradia",
    "type": "CL",
    "address": "11 lotiss. Essanabil Had Bradia - Maroc",
    "lat": "32.45265176057229",
    "lng": "-6.5384123123709905"
},
{
    id: uuid.v1(), "name": "Fkih Ben Salah",
    "type": "AB",
    "address": "Av. Yacoub El Mansour ang. av. Mouritanie cité administrative Fkih Ben Salah - Maroc",
    "lat": "32.508916402358096",
    "lng": "-6.689381262904839"
},
{
    id: uuid.v1(), "name": "Dar Old Zidouh",
    "type": "CL",
    "address": "202 lotiss. Al Fellah Dar Oulad Zidouh - Maroc",
    "lat": "32.308115883544",
    "lng": "-6.898749065589866"
},
{
    id: uuid.v1(), "name": "Fkih Ben salah beni Amir",
    "type": "AB",
    "address": "av Allal Ben Abdellah Fkih Ben Salah - Maroc",
    "lat": "32.49916016537824",
    "lng": "-6.69458236379352"
},
{
    id: uuid.v1(), "name": "Oulad Ayad",
    "type": "CL",
    "address": "BD MED V, HAY AL QODS- OULED AYAD",
    "lat": "32.202075718686245",
    "lng": "-6.797916454386748"
},
{
    id: uuid.v1(), "name": "Sidi Aissa",
    "type": "CL",
    "address": "centre Sidi Bibi Sidi Bibi - Maroc",
    "lat": "32.34092927469059",
    "lng": "-6.647796632484593"
},
{
    id: uuid.v1(), "name": "Oued Zem",
    "type": "AB",
    "address": "bd Mohammed V ang. rue Ibn Toumerte Oued Zem - Maroc",
    "lat": "32.86220016308009",
    "lng": "-6.566671625981016"
},
{
    id: uuid.v1(), "name": "Ait Ourir",
    "type": "CL",
    "address": "Ait Ourir - Centre",
    "lat": "31.564249196373183",
    "lng": "-7.663947807534984"
},
{
    id: uuid.v1(), "name": "Amizmiz",
    "type": "CL",
    "address": "Quartier administratif Amizmiz - Maroc",
    "lat": "31.22193451180772",
    "lng": "-8.241934950168854"
},
{
    id: uuid.v1(), "name": "Attaouia",
    "type": "CL",
    "address": "lotiss. Baddou Attaouia Ech Cheibia - Maroc",
    "lat": "31.834684833409227",
    "lng": "-7.309893560829286"
},
{
    id: uuid.v1(), "name": "Tahanaoute",
    "type": "CL",
    "address": "Route Tahanaout Ait Ourir",
    "lat": "31.354759648471756",
    "lng": "-7.951300633106808"
},
{
    id: uuid.v1(), "name": "Skhour Rhamna",
    "type": "CL",
    "address": "av Mohammed V Skhour Rehamna - Maroc",
    "lat": "32.476675977104996",
    "lng": "-7.916465771903326"
}
]

export const tech = [
  {
    "region": "Tanger-Tétouan-Al Hoceima",
    "equipe": "EQUIPE 1",
    id: uuid.v1(), "name": "Salaheddine EL HAOUMI ",
    "lat": "35.60251627369425",
    "lng": "-5.572448906754541"
},
{
    "region": "Tanger-Tétouan-Al Hoceima",
    "equipe": "EQUIPE 1",
    id: uuid.v1(), "name": "Oussama NAJAM",
    "lat": "35.11795879775895",
    "lng": "-5.027538794983748"
},
{
    "region": "Tanger-Tétouan-Al Hoceima",
    "equipe": "EQUIPE 1",
    id: uuid.v1(), "name": "Imad TERHZAZ ",
    "lat": "34.925761600558644",
    "lng": "-6.079848550899087"
},
{
    "region": "L’Oriental",
    "equipe": "EQUIPE 2",
    id: uuid.v1(), "name": "Mohamed BOUROUANI",
    "lat": "34.968717523471454",
    "lng": "-2.8211112283542463"
},
{
    "region": "L’Oriental",
    "equipe": "EQUIPE 2",
    id: uuid.v1(), "name": "Oussama RHOUFIRY",
    "lat": "32.69695786144783",
    "lng": "-1.920982966244201"
},
{
    "region": "L’Oriental",
    "equipe": "EQUIPE 2",
    id: uuid.v1(), "name": "El had GUEZRI",
    "lat": "33.33023174237207",
    "lng": "-3.0390626110548693"
},
{
    "region": "Fés-Meknés",
    "equipe": "EQUIPE 3",
    id: uuid.v1(), "name": "Mohamed MARRA",
    "lat": "34.533928354371675",
    "lng": "-4.508085151455067"
},
{
    "region": "Fés-Meknés",
    "equipe": "EQUIPE 3",
    id: uuid.v1(), "name": "Zakaria SARRAR",
    "lat": "33.459356919062",
    "lng": "-6.177955653667126"
},
{
    "region": "Fés-Meknés",
    "equipe": "EQUIPE 3",
    id: uuid.v1(), "name": "Hamza BENNIZ",
    "lat": "33.100742918766976",
    "lng": "-4.291784338168669"
},
{
    "region": "Rabat-Salé-Kenitra",
    "equipe": "EQUIPE 4",
    id: uuid.v1(), "name": "Ahmed MARIAMI",
    "lat": "34.39990912975705",
    "lng": "-6.375961024536369"
},
{
    "region": "Rabat-Salé-Kenitra",
    "equipe": "EQUIPE 4",
    id: uuid.v1(), "name": "Ayoub QOBAICHE",
    "lat": "33.82501593951886",
    "lng": "-7.118834627136738"
},
{
    "region": "Rabat-Salé-Kenitra",
    "equipe": "EQUIPE 4",
    id: uuid.v1(), "name": "Abdelkhalek ZHAR",
    "lat": "33.73579945293456",
    "lng": "-6.376902011938026"
},
{
    "region": "Béni Mellal-Khénitra",
    "equipe": "EQUIPE 5",
    id: uuid.v1(), "name": "Bouchaib LAMBARKI",
    "lat": "33.28157463734128",
    "lng": "-6.045720497044906"
},
{
    "region": "Béni Mellal-Khénitra",
    "equipe": "EQUIPE 5",
    id: uuid.v1(), "name": "Fathallah LAMZOURI ",
    "lat": "32.67911702975141",
    "lng": "-5.911069321045126"
},
{
    "region": "Béni Mellal-Khénitra",
    "equipe": "EQUIPE 5",
    id: uuid.v1(), "name": "Mohamed CHEKOUTI",
    "lat": "32.83041998102128",
    "lng": "-6.7791586425062365"
},
{
    "region": "Grand Casablanca-Settat",
    "equipe": "EQUIPE 6",
    id: uuid.v1(), "name": "Ibrahim HAILEM ",
    "lat": "33.69775739441914",
    "lng": "-7.32068378879493"
},
{
    "region": "Grand Casablanca-Settat",
    "equipe": "EQUIPE 6",
    id: uuid.v1(), "name": "El Hachimi RAKI",
    "lat": "33.216184347953494",
    "lng": "-6.949249447903502"
},
{
    "region": "Grand Casablanca-Settat",
    "equipe": "EQUIPE 6",
    id: uuid.v1(), "name": "Youssef ATOUMIL",
    "lat": "33.049348229081794",
    "lng": "-8.515178905059736"
},
{
    "region": "Marrakech-Safi",
    "equipe": "EQUIPE 7",
    id: uuid.v1(), "name": "Nourddine ADAIKI",
    "lat": "32.653967244389996",
    "lng": "-8.83564911120142"
},
{
    "region": "Marrakech-Safi",
    "equipe": "EQUIPE 7",
    id: uuid.v1(), "name": "Youssef FARJAOUI",
    "lat": "31.99485061831532",
    "lng": "-7.525200577310399"
},
{
    "region": "Marrakech-Safi",
    "equipe": "EQUIPE 7",
    id: uuid.v1(), "name": "Rachid ZERBAN",
    "lat": "31.691144088340007",
    "lng": "-9.334702759199418"
},
{
    "region": "Drâa-Tafilalet",
    "equipe": "EQUIPE 8",
    id: uuid.v1(), "name": "Atmane JARDINI",
    "lat": "31.934356379856784",
    "lng": "-4.685191133283705"
},
{
    "region": "Drâa-Tafilalet",
    "equipe": "EQUIPE 8",
    id: uuid.v1(), "name": "Mohamed AFALLAH",
    "lat": "31.370975902712924",
    "lng": "-6.574195501195515"
},
{
    "region": "Drâa-Tafilalet",
    "equipe": "EQUIPE 8",
    id: uuid.v1(), "name": "Youssef BENZZAOU",
    "lat": "30.189281693682236",
    "lng": "-5.743632798612782"
},
{
    "region": "Souss-Massa",
    "equipe": "EQUIPE 9",
    id: uuid.v1(), "name": "Aissam KAIDI",
    "lat": "31.051133005639954",
    "lng": "-9.608917948804255"
},
{
    "region": "Souss-Massa",
    "equipe": "EQUIPE 9",
    id: uuid.v1(), "name": "Reda ICHIR",
    "lat": "29.68017153604971",
    "lng": "-9.470849684447364"
},
{
    "region": "Souss-Massa",
    "equipe": "EQUIPE 9",
    id: uuid.v1(), "name": "Oualid KODIJI",
    "lat": "30.245975834082508",
    "lng": "-7.885165419983165"
},
{
    "region": "Guelmim-Oued Noun",
    "equipe": "EQUIPE 10",
    id: uuid.v1(), "name": "Mohamed EL MOUTI ",
    "lat": "29.183282105507175",
    "lng": "-9.861997680556787"
},
{
    "region": "Guelmim-Oued Noun",
    "equipe": "EQUIPE 10",
    id: uuid.v1(), "name": "Mohamed SAIF",
    "lat": "28.502300956828016",
    "lng": "-11.13746571052608"
},
{
    "region": "Guelmim-Oued Noun",
    "equipe": "EQUIPE 10",
    id: uuid.v1(), "name": "Fahd BENZIN",
    "lat": "28.060314349834503",
    "lng": "-9.034072425805112"
},
{
    "region": "Laâyoune-Sakia El Hamra",
    "equipe": "EQUIPE 11",
    id: uuid.v1(), "name": "Abdelwahab JAWAD",
    "lat": "27.99663436490609",
    "lng": "-11.459200924401397"
},
{
    "region": "Laâyoune-Sakia El Hamra",
    "equipe": "EQUIPE 11",
    id: uuid.v1(), "name": "Charaf EL IDRISSI",
    "lat": "26.556121625257255",
    "lng": "-10.941105338700535"
},
{
    "region": "Laâyoune-Sakia El Hamra",
    "equipe": "EQUIPE 11",
    id: uuid.v1(), "name": "Youssef AOUA",
    "lat": "25.850400996385485",
    "lng": "-14.008594087633476"
},
{
    "region": "Dakhla-Oued Eddahab",
    "equipe": "EQUIPE 12",
    id: uuid.v1(), "name": "Faouzi EL FALAOUI",
    "lat": "24.87804444629032",
    "lng": "-14.577671918176232"
},
{
    "region": "Dakhla-Oued Eddahab",
    "equipe": "EQUIPE 12",
    id: uuid.v1(), "name": "Mohamed CHAIF",
    "lat": "23.52622530015125",
    "lng": "-13.724892803765904"
}
]


